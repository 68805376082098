body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

/* new-modal Styles */
.new-modal {
    /* display: block; 
    position: fixed;
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); */


    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    /* padding-top: 100px; */
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
}

.new-modal-content {
    background-color: white;
    margin: 5% auto;
    padding: 20px;
    border-radius: 10px;
    /* width: 80%; */
    max-width: 75%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    /* border-left: 10px solid #FFD700;  Golden Yellow left border */
    /* background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 4px;
    max-width: 75%;
    border-left: 10px solid #FFD700;   */
}

.new-modal-content .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .new-modal-content .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  .invoice-container {
    max-width: 100%;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    /* border-left: 10px solid #FFD700;  */
    border-radius: 10px;
    /* color: #555; */
  }

/* Close Button */
.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

/* Invoice Styling */
.new-invoice-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f0b900;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.company-details {
    width: 70%;
    text-align: center;
}

.company-logo {
    width: 15%;
    text-align: left;
}

.company-qr {
    width: 15%;
    text-align: right;
}

.company-name {
    font-size: 25px;
    font-weight: bold;
    color: black;
}

.company-address,
.company-contact {
    font-size: 14px;
    color: #333;
}

.invoice-info {
    text-align: right;
    width: 33%;
}

.invoice-info h2 {
    font-size: 20px;
    color: #f0b900;
}

.invoice-info p {
    font-size: 14px;
    color: #333;
}

.customer-info {
    margin-bottom: 20px;
}

.customer-info h3 {
    font-size: 20px;
    color: black;
    margin-bottom: 10px;
}

.customer-info p {
    font-size: 14px;
    color: #333;
}

.invoice-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-table th, .invoice-table td {
    padding: 10px;
    /* text-align: left; */
    border: 1px solid #ddd;
}

.invoice-table th {
    background-color: #f0b900;
    color: white;
}

.invoice-summary {
    font-size: 16px;
    margin-bottom: 20px;
}

.invoice-summary p {
    margin: 5px 0;
    font-weight: bold;
}

.invoice-footer {
    text-align: center;
    font-size: 14px;
    color: #777;
    margin-top: 20px;
}

.invoice-footer p {
    margin: 5px 0;
}
.company-details p, .invoice-info p, .customer-container p {
    margin: 2px 0; /* Reduced margin between address lines */
}

.company-details p, .invoice-info p, .customer-container p {
    padding: 0; /* Remove any padding */
}

.new-logo {
    margin-right: 15px !important;
    height: 90px;
    width: 140px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.new-container {
    display: flex;
    justify-content: space-between; /* Space between columns */
    width: 100%;
}

.column {
    width: 30%; /* Set the width of each column */
}

.left {
    text-align: left; /* Align text to the left */
    padding-top: 10px;
    width: 33% !important;
}

.center {
    text-align: center; /* Align text to the center */
    width: 33% !important;
    padding-top: 10px;
}

.right {
    text-align: right; /* Align text to the right */
    padding-top: 10px;
    width: 33% !important;
}

.customer-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
}

.invoice-conditions {
    font-size: 14px;
    color: #555;
    padding-top: 10px;
}

.invoice-conditions ul {
    padding-left: 20px;
}

.bank-details {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #333;
    margin-top: 20px;
    text-align: left;
}

.bank-details p {
    margin: 0 15px 0 0;
}
