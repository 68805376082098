.fs-wt{
    font-size: 3.75rem;
    font-weight: 700;
}

.aboutus .height{
    height: 100% !important;
}

.aboutus .text {
    color: #ffa41b;
}
.aboutus .label{
    margin: -45px 0 0 20px;
}
.aboutus p {
    color: #fff;
}