.card-custom {
	margin-bottom: 2.5rem;
	border: 1px solid #F4F4F4;
	box-shadow: 2px 3px 7px 0px rgba(82, 63, 105, 0.02);
}
/* .card-custom-bgi .card-custom-header {
	background-size: auto calc(100% + 10rem);
    background-position-x: 100%;
    background-image: url(../images/4.png);
	background-repeat: no-repeat;
	background-position-y: center;
} */
.card-custom-header {
	display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
    background-color: transparent;
	padding-top: 2.5rem
}
.symbol {
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 50%;
}
.symbol .symbol-label {
    display: flex;
    align-items: center;
    justify-content: center;
	width: 50px;
    height: 50px;
    font-weight: 500;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
	border: 1px dashed #e1e3ea;
}
.card-h2 {
	font-size: 1.5rem;
	font-weight: 600;
    line-height: 1.2;
	color: #181C32;
}
.text-link-info {
	font-size: 13px;
}
.card-custom-body {
    padding: 2rem 2.25rem;
    flex: 1 1 auto;
}
.dashboard-card {
	padding-right: 1.5rem;
    padding-left: 1.5rem;
	padding-top: 1.25rem;
    padding-bottom: 1.25rem;
	border-radius: 0.475rem;
	background-color: #ffffff;
	color: #5E6278;
}
.dashboard-card-icon {
	display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.475rem;
	margin-right: 1.25rem;
	margin-bottom: 2rem 
}
.dashboard-card-icon .symbol-label {
	display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 30px;
    height: 30px;
}
.text-gray-700 {
	font-size: 18px;
	font-weight: 600;
	line-height: 1.2rem;
}
.text-gray-500{
	color: #A1A5B7;
}
.nav-tabs .nav-link {
	border: 0;
	color: #333;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	border-bottom: 2px solid #0c98fc;
}
.warning{
    color: red;
  }

  @media screen and (min-width: 400px) {
    .card-custom-bgi .card-custom-header {
        background-size: auto calc(100% + 10rem);
        background-position-x: 100%;
        /* background-image: url(../../Images/4.png); */
        background-image: url(../images/4.png);
        background-repeat: no-repeat;
        background-position-y: center;
    }
  }
  @media screen and (max-width: 380px) {
    .card-custom-bgi .card-custom-header {
        background-size: auto calc(100% + 10rem);
        background-position-x: 100%;
        /* background-image: url(../images/4.png); */
        background-repeat: no-repeat;
        background-position-y: center;
    }
}
