* {
	padding: 0;
	margin: 0;
}
html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html, body {
	scroll-behavior: smooth;
	overflow-x: hidden;
	height: 100%;
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
	display: flex;  
	flex-direction: column;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.5;
	background-color: #FBFBFB;
	color: #565151;
	font-family: Inter, Helvetica, "sans-serif";
}
#root {
    flex: 1;
	display: flex;
	flex-direction: column;
}
.theme-flex-col {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}
a {
	text-decoration: none;
	color: #7e8299;
}
/* Top Bar */
.top-header {
	transition: none;
    display: flex;
    align-items: stretch;
	background-color: #ffffff;
	box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
}
.topbar-section {
	z-index: 100;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
}
.topbar-section.topbar-theme {
	height: 70px;
	border-bottom: 1px solid #e1e3ea;
}
.sidebar-brand {
	padding-inline: 1.5rem;
	height: 70px;
	width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-shrink: 0;
	border-right: 1px solid #e1e3ea;
}
.sidebar-link-logo {
	transition: color 0.2s ease;
}
.sidebar-link-logo img {
	display: inline;
	height: 68px;
}
.sidebar-toggle-menu {
	background-color: #f7f7f7;
    border: 0;
    padding: 0.75rem;
}
.user-notifications {
	position: relative;
}
.mx-custom {
	margin-inline: 0.65rem;
}
.notification-count-active {
	width: 8px;
    height: 8px;
    border-radius: 100%;
    display: inline-block;
    background: red;
    position: absolute;
    top: 2px;
    right: 1px;
}
.user-icon {
    width: 35px;
    height: 35px;
    background: #dadfe9;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Theme Wrapper */
.theme-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1 auto;
	min-width: 0;
	margin-top: 70px;
	margin-left: 0px;
	transition: margin-left 0.3s ease, margin-right 0.3s ease;
}
/* Sidebar */
.sidebar {
    position: fixed;
    z-index: 105;
    top: 0;
    bottom: 0;
    left: 0;
}
.sidebar-theme {
	background-color: #ffffff;
    border-right: 0;
}
.sidebar-fixed {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
    flex-shrink: 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 70px;
    margin-bottom: 0px;
	padding-top: 40px;
}
.show-sidebar {
	width: 240px;
}
.close-sidebar {
	width: 62px;
	overflow: hidden;
}
.container-wrapper {
	padding-left: 240px;
    display: flex;
    flex-direction: column;
	justify-content: space-between;
    flex: 1 auto;
    min-width: 0;
	/* background-image: url('http://localhost:52164/static/media/bg.cc28a43f04516bdb43d0.jpg'); */
}
.loginWrapper#wrapper #content-wrapper{
	/* background: url(../images/bg.jpg) center center/cover no-repeat !important; */
	height: 100%;
	width: 100%;
	/* position: fixed; */
	position: relative;
}
.close-sidebar + .container-wrapper {
	padding-left: 62px;	
}
.sidebar-nav-group {
	display: block;
	position: relative;
	width: 100%;
}
.sidebar-nav-link {
	width: 100%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    position: relative;
}
.menu-icon {
	margin: 0.8rem;
	margin-left: 1.2rem;
}
.menu-item {
	opacity: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4rem;
    z-index: 10;
    font-weight: 500;
    margin: 0;
    color: #7e8299;
    transition: 0.2s opacity;
}
.accordion-menu .accordion-button {
	position: relative;
	z-index: 1;
}
.sidebar-nav-group:hover::before, .accordion-menu .active .accordion-button:hover::before {
	content: "";
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: #f0f9ff;
	border-left: 11px solid #199bfe;
    opacity: 1;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    left: -0.35rem;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
}

.subMenu.active{
	background-color: #cde5f5;
    border-left: 11px solid #199bfe;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
}
/* .sidebar-nav-group.active::before, .sidebar-accordion-wrapper:nth-of-type(1) .accordion-menu .active .accordion-button::before { */
	.sidebar-nav-group.active::before, .accordion-menu .active .accordion-button::before {
	content: "";
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: hsl(205, 71%, 86%);
	border-left: 11px solid #199bfe;
    opacity: 1;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    left: -0.35rem;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
}
.accordion-button::after {
	background-size: 1rem;
}
.no-subnav .accordion-button::after {
	content: none;
}
.sidebar-accordion-wrapper .accordion-body {
	padding: 0;
}
.sidebar-accordion-wrapper .accordion-item:first-of-type .accordion-button {
	border-radius: 0;
	background-color: #ffffff;
	box-shadow: none;
	padding-block: 0.8rem;
}
.sidebar-accordion-wrapper .accordion-item:first-of-type,
.sidebar-accordion-wrapper .accordion-item:last-of-type {
	border-radius: 0;
	border: 0;
}
.sidebar-accordion-wrapper .accordion-button {
	font-size: 14px;
}
.sidebar-accordion-wrapper .menu-label, .sidebar-accordion-wrapper .submenu-label {
	margin-left: 0.5rem;
}
.close-sidebar .menu-label {
	display: none;
}
.submenu-list-links {
	display: flex;
    flex-direction: column;
    width: 100%;
}
.submenu-list-links .submenu-links {
	padding: 0.5rem 0.8rem 0.15rem 1.5rem;
	font-size: 14px;
}


/* Scrollbar */
*::-webkit-scrollbar {
    /* width: 8px; */
    scrollbar-width: thin;
	scrollbar-color: #C4CAD0 #ffffff;
}
*::-webkit-scrollbar-track {
    background: #ffffff;
}
*::-webkit-scrollbar-thumb {
    background-color: #C4CAD0;
    border-radius: 8px;
    border: 2px solid #ffffff;
}
/* Container */
.custom-container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.footer {
	background-color: #ffffff;
	transition: left 0.3s ease, right 0.3s ease;
    display: flex;
    align-items: center;
	justify-content: space-between;
	padding: 0.85rem 1.5rem;
	margin-top: 1.5rem;
}
.copyright-text {
	font-size: 13px;
	color: #333;
}
.copyright-text span {
	color: #9f9f9f;
}
/* Card */
.card {
	border: 1px solid #F4F4F4;
	border-radius: 0.625rem;
	box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
}
.card-header {
	display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 70px;
    padding: 0 2.25rem;
	background-color: #ffffff;
	border-bottom: 1px solid #F4F4F4;
}
.card-title h3 {
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.2;
	color: #181C32;
}
.card-body {
	padding: 2.25rem;
}

.btn-primary-custom {
	color: #fff;
	background-color: #009ef7;
	border-color: #009ef7;
	border-radius: 0.425rem;
	font-size: 0.85rem;
	padding: 0.4rem 1rem;
}
.btn-primary-custom:hover, .btn-primary-custom:focus {
	color: #fff;
	background-color: #009ef7;
	border-color: #009ef7;
}
.navbarOpen-sm{
	display: none;
}

/* @media screen and (min-width: 480px) {
	.custom-container {
		max-width: none;
		padding-right: 20px;
    	padding-left: 20px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
} */
.dimension-card{
	display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and (min-width: 380px) {
	/* .custom-container {
		max-width: none;
		padding-right: 20px;
    	padding-left: 20px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	} */
	.sidebar-brand {
		padding-inline: 1.5rem;
		height: 70px;
		width: 160px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		flex-shrink: 0;
		border-right: 1px solid #e1e3ea;
	}
}

@media screen and (min-width: 1400px) {
	.custom-container {
		max-width: 1320px;
	}

	.custom-container.rate {
		max-width: 600px;
	}
}
@media screen and (min-width: 992px) {
	.custom-container {
		padding-right: 30px;
    	padding-left: 30px;
		
	}

	.rate{
		max-width: 600px;
	}
}
@media screen and (max-width: 991.98px) {
	.custom-container {
		max-width: none;
		padding-right: 20px;
    	padding-left: 20px;
	}
}

/* Alerts */

.alert.alert-custom {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 1.5rem 2rem;
  }
  
  .alert.alert-custom.alert-light-primary {
	background-color: #e1f0ff;
	border-color: transparent;
  }
  
  .alert.alert-custom.alert-light-danger {
	background-color: #ff5b3629;
  }
  
  .alert.alert-custom .alert-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 1.25rem 0 0;
  }
  
  .alert.alert-custom .alert-text {
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
  }
  
  .alert.alert-custom.alert-light-primary .alert-text {
	color: #3699ff;
  }
  
  .alert.alert-custom.alert-light-danger .alert-text {
	color: #ff5b36;
	font-size: 12px;
  }
  
  .alert.alert-custom.alert-light-danger .alert-close svg {
	color: #ff5b36;
  }
  
  .alert.alert-custom .alert-close svg {
	font-size: 0.75rem;
  }
  
  .alert.alert-custom .alert-close {
	padding: 0 0 0 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	line-height: 0;
  }
  
  .alert.alert-custom .alert-close .close {
	text-shadow: none !important;
	cursor: pointer;
	line-height: 0;
  }
  
  .alert.alert-custom.alert-light-primary .alert-close i {
	color: #3699ff;
	font-size: 0.75rem;
  }
  
  .alert.alert-custom.alert-light-primary .alert-icon i {
	color: #3699ff;
  }
  
  .alert.alert-custom .alert-icon i {
	font-size: 2.4rem;
  }
  
  .modal-body {
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    position: relative;
}

.file-upload {
    border: 3px dashed #dee2e6;
    display: block;
    padding: 1rem;
    text-align: center;
}
.bg-orange{
	background-color: orange;
}

section { 
	display: block;
  }

 .h-60{
	height: 60px;
 } 
 .fs-10{
	font-size: 10px
 }
 .fs-12{
	font-size: 12px
 }
.v-a-b{
	vertical-align:bottom !important;
}
.lh-10{
	line-height: 10px;
}
.lh-0{
	line-height: 0px;
}

.border-b-1{
	border-bottom: 1px solid;
}
.mb-5{
	margin-bottom: 5px !important;
}
.mt-20{
	margin-top: 20px;
}
.i-mb-4{
	margin-bottom: 1.5rem !important;
  }

#container{width:100%;}
#left{float:left;width:100px;}
#right{float:right;width:100px;}
#center{margin:0 auto;width:100px;}

.color-g{
	color: green;
}

/* @media screen and (max-width: 380px) {
	.show-sidebar {
		width: 62px;
		overflow: hidden;
	}
	.show-sidebar .menu-label {
		display: none;
	}
	.show-sidebar + .container-wrapper {
		padding-left: 62px;	
	}
	.navbarOpen-lg{
		display: none;
	}
} */