
/* .invoice-box table, th, td {
  border: 2px solid black;
} */
.modalBackground {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
    /* z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center; */
  }
  
  .modalContainer {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 4px;
    max-width: 75%;
    /* width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px; */
  }

  .modalContainer.update-tds {
    max-width: 50%;
  }
  
  .modalContainer .title {
    /* display: inline-block; */
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }
  
  .invoice-box {
    max-width: 100%;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
  }
  
  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
  }
  
  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box .vertical-align {
    vertical-align: middle;
  }


  /* .invoice-box table tr td:nth-child(1) {
    text-align: left;
  } */
  
  .invoice-box table tr.item td:nth-child(4) {
    text-align: right;
  }
  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }
  
  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }
  
  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }
  
  .invoice-box table tr.heading td {
    /* background: #eee;     */
    background: #ebc5c5;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
  
  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }
  .invoice-box table tr.heading td {
    border: 1px solid white;
  }
  .invoice-box table tr.item td {
    border: 1px solid #eee;
  }
  
  .invoice-box table tr.item.last td {
    border-bottom: none;
  }
  
  .invoice-box table tr.total td:nth-child(1) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }
  .invoice-box table tr.total{
      text-align: right;
  }

  table tr.heading td {
    text-align: center;
    vertical-align: middle;
  }

  .invoice-box table td.currancyText {
    vertical-align: bottom;
  }


  @media print {
    tr.vendorListHeading {
        background-color: #1a4567 !important;
        print-color-adjust: exact; 
    }
}

@media print {
    .vendorListHeading th {
        color: white !important;
    }

    #important_thing {
      width:100%;
      height:100%;
    }
}
  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }
  
    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
  }
  
  /** RTL **/
  .invoice-box.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  }
  
  .invoice-box.rtl table {
    text-align: right;
  }
  
  .invoice-box.rtl table tr td:nth-child(2) {
    text-align: left;
  }

  .brandingInvoice{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bg-aliceblue{
    background-color: aliceblue;
  }

  .c-head {
    font-weight: bold;
    font-size: 1.5rem !important;
    /* color: #f63b00; */
  }
  .c-subhead{
    /* color: #f63b00; */
  }
  
  .bg-c-head{
    background: #ebc5c5;
  }
.bg-success{
  background-color: #deefde !important;
}

.bg-danger{
  background-color: #efdee1 !important;
}
  .w-0 {
    width: 0%;
  }
  .w-2 {
    width: 2%;
  }
  .w-3 {
    width: 3%;
  }
  .w-5 {
    width: 5%;
  }
  .w-8 {
    width: 8%;
  }
  .w-10 {
    width: 10%;
  }
  .w-20 {
    width: 20%;
  }
  .w-15 {
    width: 15%;
  }
  .w-16 {
    width: 16%;
  }
  .w-30 {
    width: 30%;
  }
  .w-33 {
    width: 33%;
  }
  .w-40 {
    width: 40%;
  }
  .w-45 {
    width: 45%;
  }
  .w-48 {
    width: 48%;
  }
  .w-300{
    width: 300px;
  }
  .wt-100{
    width: 100px
  }
  .h-50{
    height: 50px !important;
  }
  .m-l-130{
    margin-left: 100px !important;
  }
  .m-l-35{
    margin-left: 35%;
  }

  .m-l-15{
    margin-left: 2.5rem;
  }

  .m-6{
    margin: 4.0rem !important;
  }
  .table-item{
    margin: -6px;
    width: 101.25% !important;
  }
  .text-right{
    text-align: right;
  }

  .text-center{
    text-align: center;
  }
  
  .t-a-c{
    text-align: center !important;
  }
.t-a-l{
  text-align: left;
}

.border-top-pr {
  border-top: 2px solid;
}
.border-right-pr {
  border-right: 2px solid;
}

.color-r{
  color: red !important;
}
.m-l-50{
  margin-left: 50px !important;
}
.m-l-45{
  margin-left: 45px !important;
}
  .border-r-l-2{
    border-right: 2px solid !important;
    border-left: 2px solid !important;
  }

.border-b{
  border-bottom: 2px solid;
}

.border-l{
  border-left: 2px solid;
}

.respnsive-custom-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  position: relative;
  max-height: 400px;
}


.respnsive-custom-table::-webkit-scrollbar {
  height: 8px;
}
.respnsive-custom-table::-webkit-scrollbar {
  width: 8px;
}

.sticky-header{
  top: -1px;
  position: sticky;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
}

.cursor-p{
  cursor: pointer;
}

.color-b{
  color: blue;
}

.mt-10{
  margin-top: 10px;
}

.mt-25{
  margin-top: 25% !important;
}

@media screen and (max-width: 767px) {
  .FilteredResult {
      overflow-x: scroll;
  }
  ::-webkit-scrollbar {
      height: 6px;
  }
  .respnsive-custom-table::-webkit-scrollbar {
      width: 4px;
  }

}
  /* #brandingInvoice {
         float: left; } */
         #brandingInvoice .logo, #brandingInvoice .site-title {
          display: inline-block;
          vertical-align: middle; }
        #brandingInvoice .logo {
          /* margin-right: 15px; */
          /* height: 78px;  */
          margin-right: 15px !important;
          height: 90px;
          width: 170px !important;
        }
        #brandingInvoice .qrcode, #brandingInvoice .site-title {
          display: inline-block;
          vertical-align: middle; }
        #brandingInvoice .qrcode {
          /* margin-right: 15px; */
          height: 100px; }  
        #brandingInvoice .site-title {
          font-size: 18px;
          font-size: 1.125em;
          margin: 0;
          color: #f63f3f; }
          #brandingInvoice .site-title span {
            color: white; }   

           .invoice-subheading{
            font-size: 11px;
            line-height: 1.5em;
            font-size: 1rem !important;
           }