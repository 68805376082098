/* Notifications */

.notifications-lists {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.notification-flex {
	display: flex;
	align-items: flex-start;
	width: 100%;
	border-bottom: 1px solid #ebebeb;
	padding: 1rem;
	position: relative;
}
.unread-notification {
	background-color: #ebebeb;
}
.notification-icon {
	padding: 0.25rem;
	margin-right: 1rem;
}
.unread-notification .notification-desc:before {
	content: "";
	position: absolute;
	right: 8px;
	top: 50%;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	background-color: #199bfe;
}
.text-date {
	color: #a8a8a8;
	font-size: 11px;
	font-weight: 300;
}
.text-desc {
    color: #212529;
    font-size: 13px;
    line-height: 1.6;
    margin-bottom: 5px;
}

/* My Account */
.account-flex {
	padding: 0 16px;
	position: relative;
	width: 100%;
}
.account-list {
	padding-top: 16px;
    padding-bottom: 13px;
    padding-right: 8px;
    justify-content: space-between;
}
.account-list, .account-media {
    display: flex;
    align-items: center;
}
.account-list:not(:last-child) {
	border-bottom: 1px solid #eaecf0;
}
.ml-1_5 {
    margin-left: 1rem;
}
.account-list-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.account-list-label {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #6b7586;
    margin-top: 4px;
}
.mar-spacer {
    height: 2.625rem;
    display: block;
    width: 100%;
}
.bg-theme {
    background: #f5f7fb;
    height: 12px;
}
.btn-logout {
	background: #fff;
    padding: 14px;
    font-size: 14px;
    line-height: 18px;
    color: #e22900;
    border-radius: 0;
    letter-spacing: .04em;
    border: 1px solid #e22900;
}
.btn-logout:hover, .btn-logout:hover {
	color: #e22900;
    border: 1px solid #e22900;
}
/* search */
.nav-search {
	position: relative;
}
.nav-search .form-control {
	padding-left: 1.75rem;
}
.nav-search span {
	position: absolute;
	left: 8px;
    top: 8px;
}