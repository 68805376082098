
/* Pre-loader */

.log-out-layer {
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  
  #overlayer {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    background: #ffffff94;
  }
  
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(135deg);
    z-index: 999;
  }
  
  .pre-loader {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .pre-loader .line {
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    border-bottom: 2px solid #3699ff;
  }
  
  .pre-loader .line:nth-child(1) {
    width: 20px;
    height: 20px;
    animation: anim4 600ms linear infinite;
  }
  
  .pre-loader .line:nth-child(2) {
    width: 40px;
    height: 40px;
    animation: anim4 800ms linear infinite;
  }
  
  .pre-loader .line:nth-child(3) {
    width: 60px;
    height: 60px;
    animation: anim4 1000ms linear infinite;
  }
  
  .pre-loader .line:nth-child(4) {
    width: 80px;
    height: 80px;
    animation: anim4 1200ms linear infinite;
  }
  
  .zi-popup{
    z-index: 10000;
  }
@keyframes anim4 {
    to {
      transform: rotate(360deg);
    }
  }