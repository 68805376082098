
@media screen {
    p.bodyText {font-family:verdana, arial, sans-serif;}
}

@media print {
    @page{
        size: auto !important;
    }
    p.bodyText {font-family:georgia, times, serif;}
}
@media screen, print {
    p.bodyText {font-size:10pt}
}