/* xs */
@media (min-width: 320px) {
  .container {
    max-width: 720px;
}
.rate-card .height{
  height: 700px !important;
  padding-top: 40px;
}

.contact-card .height{
  height: 100% !important;
  /* padding-top: 40px; */
}

.ftco-intro {
    margin-top: -300px;
    margin-left: 0px;
  } 
  .hero-wrap {
    background-position: top center !important;
    height: 450px !important;
  } 
  /* .hero-wrap .overlay {
    bottom: 0px !important;
  } */
  .ftco-section {
    padding: 4em 0;
    position: relative; }

    .project {
      height: 100% !important;
      margin-left: 0px !important;
    }
  .project .items{
    margin-left: 22% !important;
    width: 60px !important;
    height: 60px !important;
    float: left;
    box-shadow: 0 0 0 9px #c1984145;
    margin-top: 10px;
  }
 
  .mb-4, .my-4 {
    margin-bottom: 10.rem !important;
}
.text-center {
  text-align: center !important; 
  margin-left: 0px;
}
.mt-6,
.my-6 {
  margin-top: 0rem !important; }

.mt-240{
  margin-top: 50px !important;
}
.w-400 {
  width: 100% !important;
}   
.ml-50{
  margin-left: -50px !important;
}

.ftco-intro .services-1 {
  background: #5b7c99;
  width: 105px;
  height: 70%;
  padding: 28px;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; 
}
.services-1 .card-icon {
  width: 45px;
  height: 60px;
  margin-left: 5px;
  }
  .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; 
    padding-left: 0rem;
  }
  .ftco-intro .services-1 h3 {
    color: #fff;
    font-size: 12px;
}
.pt-5 {
  padding-top: 0rem !important; 
}
.ml-2 {
  margin-left: 0rem !important; 
}
.pb-2 {
  padding-bottom: 0.5rem !important; 
}

.rate-card .mt-5 {
  margin-top: 3rem !important;
}
.booking .mt-5 {
  margin-top: 3rem !important;
}
}

@media (min-width: 320px) and (max-width: 474px) {
  /* Your CSS rules for screens between 320px and 474px */
  .ftco-intro {
    margin-top: -300px;
    margin-left: 35px;
  } 
}

@media (min-width: 475px) {
  .container {
    max-width: 720px;
}
.rate-card .height{
  height: 700px !important;
  /* padding-top: 40px; */
}

.contact-card .height{
  height: 100% !important;
  /* padding-top: 40px; */
}
.ftco-intro .service-1{
  width: 100px !important;
  height: 70% !important;
  padding: 28px !important;
}
.ftco-intro {
    margin-top: -300px;
    margin-left: 100px;
  } 
  .hero-wrap {
    background-position: top center !important;
    height: 450px !important;
  } 
  /* .hero-wrap .overlay {
    bottom: 0px !important;
  } */
  .ftco-section {
    padding: 4em 0;
    position: relative; }
  .project {
    height: 100% !important;
    margin-left: 0px !important;
}
  .project .items{
    margin-left: 22% !important;
    width: 120px !important;
    height: 120px !important;
    float: none;
  }
  .img-fluid{
    margin-left: 25px;
    margin-top: 40px;
  }
  .mb-4, .my-4 {
    /* margin-bottom: 1.5rem !important; */
    margin-bottom: 10.rem !important;
    /* margin-left: 50% !important; */
}
.text-center {
  text-align: center !important; 
  margin-left: 0px;
}
.mt-6,
.my-6 {
  margin-top: 0rem !important; }

.mt-240{
  margin-top: 50px !important;
}
.w-400 {
  width: 100% !important;
}   
.ml-50{
  margin-left: -50px !important;
}
.services-1 .card-icon {
  width: 72px;
  height: 80px;
  margin-left: 1.5rem;
  }
  .pt-5 {
  padding-top: 3rem !important; 
}

.ml-2 {
  margin-left: 0.5rem !important; 
}
.ftco-intro .services-1 {
  background: #5b7c99;
  width: 175px;
  height: 100%;
  padding: 28px;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; 
}
.ftco-intro .services-1 h3 {
  color: #fff;
  font-size: 16px;
}
.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; 
  padding-left: 1rem;
}
.rate-card .mt-5 {
  margin-top: 5rem !important;
}
.booking .mt-5 {
  margin-top: 5rem !important;
}
}

/* sm */
@media (min-width: 640px) {
  .container {
    max-width: 720px;
}
.rate-card .height{
  height: 700px !important;
  /* padding-top: 40px; */
}

.contact-card .height{
  height: 100% !important;
  /* padding-top: 40px; */
}

.ftco-intro {
    margin-top: -300px;
    margin-left: 100px;
  } 
  .hero-wrap {
    background-position: top center !important;
    height: 450px !important;
  } 
  /* .hero-wrap .overlay {
    bottom: 0px !important;
  } */
  .ftco-section {
    padding: 4em 0;
    position: relative; }
  .project {
    height: 100% !important;
    margin-left: 0px !important;
}
  .project .items{
    margin-left: 22% !important;
    width: 120px !important;
    height: 120px !important;
    float: none;
  }
  .img-fluid{
    margin-left: 25px;
    margin-top: 40px;
  }
  .mb-4, .my-4 {
    /* margin-bottom: 1.5rem !important; */
    margin-bottom: 10.rem !important;
    /* margin-left: 50% !important; */
}
.text-center {
  text-align: center !important; 
  margin-left: 0px;
}
.mt-6,
.my-6 {
  margin-top: 0rem !important; }

.mt-240{
  margin-top: 50px !important;
}
.w-400 {
  width: 100% !important;
}   
.ml-50{
  margin-left: -50px !important;
}

}

/* md */
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
    .rate-card .height{
      height: 600px !important;
      padding-top: 40px;
    }
    
    .ftco-intro {
        margin-top: -300px;
        margin-left: -400px;
      } 
      .hero-wrap {
        background-position: top center !important;
        height: 450px !important;
      } 
      .ftco-section {
        padding: 4em 0;
        position: relative; }
      .project {
        height: 100% !important;
        margin-left: 0px !important;
    }
      .project .items{
        margin-left: 22% !important;
        width: 120px !important;
        height: 120px !important;
        float: none;
      }
      .img-fluid{
        margin-left: 25px;
        margin-top: 40px;
      }
      .mb-4, .my-4 {
        /* margin-bottom: 1.5rem !important; */
        margin-bottom: 10.rem !important;
        /* margin-left: 50% !important; */
    }
    .text-center {
      text-align: center !important; 
      margin-left: 0px;
    }
    .mt-6,
    .my-6 {
      margin-top: 0rem !important; }

    .mt-240{
      margin-top: 50px !important;
    }
    .w-400 {
      width: 100% !important;
    }   
    .ml-50{
      margin-left: -50px !important;
    }
    .ftco-intro .services-1 {
      background: #5b7c99;
      width: 175px;
      height: 100%;
      padding: 28px;
      position: relative;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease; 
    }

      .media-body {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1; 
        padding-left: 1rem;
      }
      .ftco-intro .services-1 h3 {
        color: #fff;
        font-size: 16px;
    }
}

/* lg */
@media (min-width: 1024px) {
    .container {
        max-width: 960px;
    }
    .rate-card .height{
      height: 650px !important;
    }
    
    .contact-card .height{
      height: 650px !important;
    }

    .ftco-intro {
        margin-top: -350px;
        margin-left: -400px;
      } 
      .ftco-section {
        padding: 4em 0;
        position: relative; }
      .hero-wrap {
        background-position: top center !important; 
        height: 551px !important;
        top: -60px;
      } 
      .project {
        height: 100% !important;
        margin-left: 0px !important;
    }
      .project .items{
        margin-left: 22% !important;
        width: 120px !important;
        height: 120px !important;
        float: none;
      }
      .img-fluid{
        margin-left: 25px;
        margin-top: 40px;
      }
      .mb-4, .my-4 {
        /* margin-bottom: 1.5rem !important; */
        margin-bottom: 10.rem !important;
        /* margin-left: 40% !important; */
    }
    .text-center {
      text-align: center !important; 
      margin-left: 0px;
    }
    .mt-240{
      margin-top: 165px !important;
    }
    .mt-6,
    .my-6 {
      margin-top: 0rem !important; 
    }
    
    .pt-80 {
      padding-top: 25px;
    }
    .rate-card .pt-80 {
      padding-top: 80px;
    }
    .ml-50{
      margin-left: 0px !important;
    }
}

/* xl */
@media (min-width: 1280px) {
    .container {
        max-width: 960px;
    }
    .height{
      height: 551px !important;
    }
    .ftco-intro {
        margin-top: -425px;
        margin-left: -400px;
      } 
      .ftco-section {
        padding: 4em 0;
        position: relative; }
      .hero-wrap {
        background-position: top center !important; 
        height: 640px !important;
        top: -60px;
      } 
      .project {
        height: 100% !important;
        margin-left: 0px !important;
    }
      .project .items{
        margin-left: 22% !important;
        width: 120px !important;
        height: 120px !important;
        float: none;
      }
      .img-fluid{
        margin-left: 25px;
        margin-top: 40px;
      }
      .mb-4, .my-4 {
        /* margin-bottom: 1.5rem !important; */
        margin-bottom: 10.rem !important;
        /* margin-left: 42% !important; */
    }
    .text-center {
      text-align: center !important; 
      margin-left: 0px;
    }
    .mt-240{
      margin-top: -100px !important;
    }
    .pt100{
      padding-top: 100px;
     }
     .mt-6,
    .my-6 {
      margin-top: 6rem !important; 
    }
    .pt-80{
      padding-top: 80px;
    }   
    .ml-50{
      margin-left: 0px !important;
    } 
}

/* 2xl */
@media (min-width: 1536px) {
    .container {
        max-width: 960px;
    }
    .height{
      height: 551px !important;
    }
    .ftco-intro {
        margin-top: -550px;
        margin-left: -400px;
      } 
      .ftco-section {
        padding: 4em 0;
        position: relative; }
      .hero-wrap {
        /* background-position: top center !important; 
        height: 640px !important;
        top: -60px; */
        background-position: 50% 25% !important;
        height: 799px !important;
        top: -60px;
      } 
      .project {
        height: 100% !important;
        margin-left: 0px !important;
    }
      .project .items{
        margin-left: 22% !important;
        width: 120px !important;
        height: 120px !important;
        float: none;
      }
      .img-fluid{
        margin-left: 25px;
        margin-top: 40px;
      }
      .mb-4, .my-4 {
        margin-bottom: 25rem !important;
        margin-bottom: 10.rem !important;
        /* margin-left: 42% !important; */
    }
    .mt-240{
      margin-top: 175px !important;
    }
    .text-center {
      text-align: center !important; 
      margin-left: 0px;
    }
    .mt-25{
      margin-top: 25rem !important;
    }

   .pt100{
    padding-top: 100px;
   }
   .mt-6,
    .my-6 {
      margin-top: 6rem !important; 
    }
    .pt-80{
      padding-top: 80px;
    }
    .ml-50{
      margin-left: 0px !important;
    }
    
}